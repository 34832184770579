export const env = {
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT ?? 'development',
  baseUrl: process.env.NEXT_PUBLIC_BASE_URL ?? 'http://localhost:3000',
  apiUrl: process.env.NEXT_PUBLIC_API_URL ?? 'https://staging.api.kindest.com',
  googleClientId:
    process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ??
    '620635014106-abqag4s8u4uvbp8o611cnejh23u7bl7e.apps.googleusercontent.com',
  recaptchaSiteKey:
    process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? '6Lf6ZSUqAAAAAOw-2B-LjV6wWbYiVAjWgMisme3r',
  stripeKey:
    process.env.NEXT_PUBLIC_STRIPE_KEY ?? 'pk_test_OEPyEYVDhr2r9IFx8VcrYG82',
  algolia: {
    appId: 'CJWBJBIQS5',
    einsApiKey:
      process.env.NEXT_PUBLIC_ALGOLIA_API_KEY ??
      '022b7d0cf521535a7d2d71f342585115',
    einsIndex: process.env.NEXT_PUBLIC_ALGOLIA_EINS_INDEX ?? 'EINS',
    contactsIndex:
      process.env.NEXT_PUBLIC_ALGOLIA_CONTACTS_INDEX ?? 'staging_CONTACTS',
    institutionsIndex:
      process.env.NEXT_PUBLIC_ALGOLIA_INSTITUTIONS_INDEX ??
      'staging_INSTITUTIONS',
    transactionsIndex:
      process.env.NEXT_PUBLIC_ALGOLIA_TRANSACTIONS_INDEX ??
      'staging_TRANSACTIONS',
    workspacesIndex:
      process.env.NEXT_PUBLIC_ALGOLIA_WORKSPACES_INDEX ?? 'staging_WORKSPACES',
  },
  aws: {
    s3bucketImages:
      process.env.NEXT_PUBLIC_AWS_S3_BUCKET_IMAGES ?? 'dev-kindest-images',
    cloudFrontEndpointImages:
      process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_IMAGES_ENDPOINT ??
      'https://d1wzs5roaty0du.cloudfront.net',
  },
  constantContact: {
    clientId:
      process.env.NEXT_PUBLIC_CONSTANT_CONTACT_CLIENT_ID ??
      '43423a4a-db00-4c3c-84db-f044ec32c6bd',
  },
  plaid: {
    env: process.env.NEXT_PUBLIC_PLAID_ENV ?? 'sandbox',
    key: 'aae29581209977096d6815524e97ab',
  },
  intercom: {
    appId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? 'y00kkabu',
  },
  facebook: {
    appId: '2385811158389153',
    version: 'v7.0',
  },
  fathomAnalytics: {
    siteId: 'PAWJOQEV',
  },
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  },
  liveSessionTrackId: process.env.NEXT_PUBLIC_LIVESESSION_TRACK_ID,
  posthog: {
    key: process.env.NEXT_PUBLIC_POSTHOG_KEY,
    host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    active: process.env.NEXT_PUBLIC_POSTHOG_ACTIVE === 'true',
  }
}
