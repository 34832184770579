// app/providers.tsx
'use client'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { env } from '~/config/env'
import { useEffect } from 'react'

export function PosthogProvider({
    children,
    bootstrapData
}: {
    children: React.ReactNode
    bootstrapData: any
}) {
    useEffect(() => {
        posthog.init(env.posthog.key!, {
            api_host: env.posthog.host,
            person_profiles: 'identified_only',
            capture_pageview: false, // Disable automatic pageview capture, as we capture manually
            loaded: (posthog) => {
                if (process.env.NODE_ENV === 'development') posthog.debug() // debug mode in development
            },
            bootstrap: bootstrapData
        })
    }, []);

    return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}
